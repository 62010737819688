import React from 'react';
import ReactDOM from "react-dom";
import {Wrapper} from "@atttomyx/react-components";
import App from "./app";
import {themes} from "@atttomyx/shared-constants";
import {storage} from "@atttomyx/react-utils";
import {games} from "@atttomyx/react-games";
import {NAMESPACE} from "./constants";
import "./themes/light.css";
import "./themes/dark.css";

storage.setNamespace(NAMESPACE);

ReactDOM.render(
    <Wrapper
        theme={themes.LIGHT}
        branding={games.defaultBranding(themes.LIGHT)}
    >
        {(onThemeChange, snackbar, dimensions) =>
            <App
                onThemeChange={onThemeChange}
                snackbar={snackbar}
                dimensions={dimensions}
            />}
    </Wrapper>,
    document.getElementById('root')
);
