import {objects} from "@atttomyx/shared-utils";

export const sortByMistakesAndElapsed = (a, b) => {
    const mistakesA = objects.defaultIfNullOrUndefined(a.metrics ? a.metrics.mistakes : a.mistakes, 999999);
    const elapsedA = objects.defaultIfNullOrUndefined(a.metrics ? a.metrics.elapsed : a.elapsed, 999999);
    const mistakesB = objects.defaultIfNullOrUndefined(b.metrics ? b.metrics.mistakes : b.mistakes, 999999);
    const elapsedB = objects.defaultIfNullOrUndefined(b.metrics ? b.metrics.elapsed : b.elapsed, 999999);

    let ret = 0;

    if (mistakesA > mistakesB) {
        ret = 1;

    } else if (mistakesA < mistakesB) {
        ret = -1;

    } else if (elapsedA > elapsedB) {
        ret = 1;

    } else if (elapsedA < elapsedB) {
        ret = -1;
    }

    return ret;
};
