import axios from 'axios';
import {syncCachedUsers_challenge, syncCachedUsers_game} from "./cache";
import {arrays} from "@atttomyx/shared-utils";
import {games} from "@atttomyx/react-games";

export const loadChallenges = (cursor, limit, success, failure) => {
    const url = "/api/v1/challenge/list";

    axios.get(url)
        .then(response => {
            const json = response.data;

            json.challenges.forEach(syncCachedUsers_challenge);

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const loadChallenge = (challengeId, success, failure) => {
    const url = "/api/v1/challenge/" + challengeId + "/";

    axios.get(url)
        .then(response => {
            const json = response.data;

            syncCachedUsers_challenge(json);

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const createChallenge = (challenge, result, success, failure) => {
    const url = "/api/v1/challenge/";
    const body = {
        difficulty: challenge.difficulty,
        userIds: challenge.userIds,
        allowJoin: challenge.allowJoin,
    };

    if (result) {
        body.difficulty = result.difficulty;
        body.board = result.board;
        body.metrics = result.metrics;
    }

    axios.post(url, body)
        .then(response => {
            const json = response.data;

            syncCachedUsers_challenge(json);

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const createRematch = (user, challenge, success, failure) => {
    const rematch = {
        difficulty: challenge.game.difficulty,
        allowJoin: false,
    };

    if (challenge.users) {
        rematch.userIds = arrays.getIds(challenge.users);

    } else if (challenge.user) {
        rematch.userIds = [user.id, challenge.user.id];

    } else if (challenge.targets || challenge.dismissed) {
        const users = games.getUsersFromTargets(challenge, true);

        rematch.userIds = arrays.getIds(users);
    }

    createChallenge(rematch, null, success, failure);
};

export const deleteChallenges = (completedIds, incompleteIds, callback) => {
    const url = "/api/v1/challenge/bulkDelete";
    const challengeIds = [];

    arrays.addAll(challengeIds, completedIds);
    arrays.addAll(challengeIds, incompleteIds);

    axios.post(url, {
        completedIds: completedIds,
        incompleteIds: incompleteIds,
    })
        .then(response => {
            callback(challengeIds);
        })
        .catch(err => {
            alert(err);
        });
};

export const acceptChallenge = (challengeId, success, failure) => {
    const url = "/api/v1/challenge/" + challengeId + "/accept";

    axios.post(url)
        .then(response => {
            const json = response.data;

            syncCachedUsers_game(json);

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const joinChallenge = (challengeId, success, failure) => {
    const url = "/api/v1/challenge/" + challengeId + "/join";

    axios.post(url)
        .then(response => {
            const json = response.data;

            syncCachedUsers_game(json);

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const completeChallenge = (challengeId, elapsed, mistakes, success, failure) => {
    const url = "/api/v1/challenge/" + challengeId + "/complete";

    axios.post(url, {
        elapsed: elapsed,
        mistakes: mistakes,
    })
        .then(response => {
            success();
        })
        .catch(err => {
            failure(err);
        });
};
