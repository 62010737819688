export const APP_NAME = "Sudoku";
export const APP_TAG_LINE = "Try to solve Sudoku puzzles faster than your friends";
export const APP_ID_APPLE = "1539297952";
export const APP_ID_GOOGLE = "io.milesoft.sudoku";

// export const API_BASE_URL = "https://sudoku-app-s25pzcrexa-uc.a.run.app";   // sand
export const API_BASE_URL = "https://sudoku-app-gv7kslcprq-uc.a.run.app";   // prod
export const WEB_BASE_URL = "https://sudoku.milesoft.app";

export const PATH_DEEP_LINK = "/deep-link";
export const PATH_CHALLENGE = PATH_DEEP_LINK + "/challenge/";

export const ACCOUNT_ID = "gM44NQIXCz4cFRK0Yflr";

export const CLOUDINARY_ID = "cuteandfuzzy";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyA9Dj9SL_KXAQw4DfB-66XmJvyAUytOMdo",
    authDomain: "cf-notifier.firebaseapp.com",
    projectId: "cf-notifier",
    storageBucket: "cf-notifier.appspot.com",
    messagingSenderId: "741330969895",
    appId: "1:741330969895:web:795f316c4aca845967f502",
    measurementId: "G-7YK8FMDCTM"
};

export const NAMESPACE = "sudoku";
export const KEY_VERSION = "version";
export const KEY_AUTH_TOKEN = "auth_token";
export const KEY_USERS = "challengers";
export const KEY_LEADER_BOARDS = "leaderboards";
export const KEY_GAME = "game";
export const KEY_MOVES = "moves";
export const KEY_RESULT = "result";

export const CHALLENGE_ID_NONE = "none";

export const OBSERVER_APP = "app";
export const OBSERVER_GAME = "game";

export const EXPLANATION_EASY = "Hides at least 1 in each row, column, and box";
export const EXPLANATION_MEDIUM = "Hides at least 3 in each row, column, and box";
export const EXPLANATION_HARD = "Hides at least 5 in each row, column, and box";

export const REQUEST_REVIEW_AFTER = 10;

export const GUESS = "guess";
export const NOTES = "notes";

export const DEFAULT_BEST = {
    elapsed: 60 * 60 * 1000,
    mistakes: 1000,
};

export const IAP_IOS_NO_BANNER_ADS = "hideBannerAds";
export const IAP_IOS_GEMS_1 = "gems1";
export const IAP_IOS_GEMS_2 = "gems2";
export const IAP_IOS_GEMS_3 = "gems3";
export const IAP_IOS_GEMS_4 = "gems4";
export const IAP_IOS_GEMS_5 = "gems5";
export const IAP_IOS_GEMS_6 = "gems6";

export const IAP_ANDROID_NO_BANNER_ADS = "hide_banner_ads";
export const IAP_ANDROID_GEMS_1 = "gems_i";
export const IAP_ANDROID_GEMS_2 = "gems_ii";
export const IAP_ANDROID_GEMS_3 = "gems_iii";
export const IAP_ANDROID_GEMS_4 = "gems_iv";
export const IAP_ANDROID_GEMS_5 = "gems_v";
export const IAP_ANDROID_GEMS_6 = "gems_vi";

// for testing the store
export const IAPS_IOS = {
    items: [
        {
            id: IAP_IOS_NO_BANNER_ADS,
            title: "Permanently remove banner ads",
            price: "$3.99",
        },
        {
            id: IAP_IOS_GEMS_1,
            title: "Pouch of gems (30)",
            price: "$0.99",
        },
        {
            id: IAP_IOS_GEMS_2,
            title: "Bowl of gems (180)",
            price: "$4.99",
        },
        {
            id: IAP_IOS_GEMS_3,
            title: "Bucket of gems (432)",
            price: "$9.99",
        },
        {
            id: IAP_IOS_GEMS_4,
            title: "Crate of gems (1035)",
            price: "$19.99",
        },
        {
            id: IAP_IOS_GEMS_5,
            title: "Vault of gems (3105)",
            price: "$49.99",
        },
        {
            id: IAP_IOS_GEMS_6,
            title: "Boatload of gems (7452)",
            price: "$99.99",
        },
    ],
};

// standard private pages
export const PAGE_USERNAME = "/username";
export const PAGE_PROFILE = "/profile";
export const PAGE_OPTIONS = "/options";
export const PAGE_ABOUT = "/about";
export const PAGE_STORE = "/store";

// custom private pages
export const PAGE_DASHBOARD = "/dashboard";
export const PAGE_LEADERBOARDS = "/leaderboards";
export const PAGE_CHALLENGE = "/challenge";
export const PAGE_GAME = "/game";

export const PAGE_HOME = PAGE_DASHBOARD;

export const PAGES_EXACT = [
    PAGE_USERNAME,
    PAGE_PROFILE,
    PAGE_OPTIONS,
    PAGE_ABOUT,
    PAGE_STORE,
    PAGE_DASHBOARD,
    PAGE_LEADERBOARDS,
    PAGE_CHALLENGE,
];

export const PAGES_STARTS_WITH = [
    PAGE_GAME,
];
