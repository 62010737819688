import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {
    Backdrop,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {
    OndemandVideo as AdIcon,
    Pause as PauseIcon,
    PlayCircleFilled as ResumeIcon,
    Replay as RematchIcon,
    Save as SaveIcon,
    Send as SendChallengeIcon
} from "@mui/icons-material";
import Timer from "../../components/timer/timer";
import {constants as games, games as gameUtils, LeaderBoard} from "@atttomyx/react-games";
import {confirm, mobile} from "@atttomyx/react-utils";
import {datetime, mocks, objects} from "@atttomyx/shared-utils";
import {ads, appStates} from "@atttomyx/shared-constants";
import * as cacheService from "../../services/cache";
import * as challengeService from "../../services/challenges";
import * as gameService from "../../services/game";
import {getStars, toScore, toSub} from "../../utils/stats";
import {sortByMistakesAndElapsed} from "../../utils/sorting";
import {GUESS, NOTES, OBSERVER_GAME, REQUEST_REVIEW_AFTER} from "../../constants";
import "./sudoku.css";

const Notes = (props) => {
    const notes = props.notes;

    const renderNote = (notes, n) => {
        return notes.indexOf(n) > -1 ? n : "\u00a0";
    };

    const renderNoteRow = (notes, n1, n2, n3) => {
        return <tr>
            <td>
                {renderNote(notes, n1)}
            </td>
            <td>
                {renderNote(notes, n2)}
            </td>
            <td>
                {renderNote(notes, n3)}
            </td>
        </tr>
    };

    return <table cellPadding="0" cellSpacing="0">
        <tbody>
        {renderNoteRow(notes, 1, 2, 3)}
        {renderNoteRow(notes, 4, 5, 6)}
        {renderNoteRow(notes, 7, 8, 9)}
        </tbody>
    </table>
}

const Square = (props) => {
    const shown = props.shown;
    const notes = props.notes;
    const guess = props.guess;
    const mistake = props.mistake;
    const paused = props.paused;
    const outer = "square"
        + " r" + props.row
        + " c" + props.column
        + " b" + props.box
        + (props.active && !paused ? " active" : "")
        + (props.highlight && shown && !paused ? " highlight" : "")
        + (guess && !paused ? " guess" : "")
        + (props.correct && !paused ? " correct" : "");

    let inner = null;

    if (notes.length > 0) {
        inner = "notes";

    } else if (mistake && !paused) {
        inner = "mistake";

    } else if (props.empty && !paused) {
        inner = "empty";
    }

    let value = "\u00a0";

    if (shown) {
        value = props.value;

    } else if (guess) {
        value = guess;

    } else if (mistake) {
        value = mistake;

    } else if (notes.length > 0) {
        value = <Notes notes={notes}/>;
    }

    return inner ?
        <div className={outer} onClick={props.onClick}>
            <div className={inner}>
                {value}
            </div>
            {"\u00a0"}
        </div> :
        <div className={outer} onClick={props.onClick}>
            {value}
        </div>
}

class Row extends Component {

    renderSquare(r, c, active, highlight) {
        const almost = this.props.almost;
        const completed = this.props.completed;
        const paused = this.props.paused;
        const squares = this.props.squares;
        const square = squares[c] || {};
        const value = square.value;
        const guess = square.guess;
        const shown = square.shown;

        return <Square
            row={r}
            column={c}
            box={square.box}
            onClick={square.onClick}
            value={value}
            shown={square.shown}
            notes={square.notes}
            guess={guess}
            mistake={square.mistake}
            correct={completed ? square.correct : null}
            empty={almost && !guess && !shown}
            active={active.row === r && active.column === c}
            highlight={highlight === value}
            paused={paused}
        />
    }

    render() {
        const r = this.props.r;
        const active = this.props.active;
        const highlight = this.props.highlight;
        const className = this.props.className;
        const classes = "row" + (className ? " " + className : "");

        return (
            <div className={classes}>
                {this.renderSquare(r, 0, active, highlight)}
                {this.renderSquare(r, 1, active, highlight)}
                {this.renderSquare(r, 2, active, highlight)}
                {this.renderSquare(r, 3, active, highlight)}
                {this.renderSquare(r, 4, active, highlight)}
                {this.renderSquare(r, 5, active, highlight)}
                {this.renderSquare(r, 6, active, highlight)}
                {this.renderSquare(r, 7, active, highlight)}
                {this.renderSquare(r, 8, active, highlight)}
            </div>
        );
    }
}

const initialize = (component, game, challenge, pause) => {
    const mine = challenge ? challenge.mine : null;
    const rows = [new Array(9), new Array(9), new Array(9),
        new Array(9), new Array(9), new Array(9),
        new Array(9), new Array(9), new Array(9)];

    let started = Date.now();
    let paused = pause ? Date.now() : null;
    let mistakes = 0;
    let completed = false;
    let stopped = null;
    let alreadyPlayed = false;
    let validatable = false;

    if (mine) {
        completed = true;
        mistakes = mine.metrics.mistakes;
        stopped = started + mine.metrics.elapsed;
        alreadyPlayed = true;
    }

    game.board.squares.forEach(square => {
        const r = square.row;
        const c = square.column;
        const value = square.value;

        rows[r][c] = {
            box: square.box,
            value: value,
            notes: [],
            shown: completed || square.shown,
            correct: completed && !square.shown,
            onClick: () => component.clickSquare(r, c, value),
        };
    });

    const moves = cacheService.getCachedMoves(gameUtils.getChallengeId(challenge));

    if (moves.rows && !completed) {
        for (let r = 0; r < 9; r++) {
            for (let c = 0; c < 9; c++) {
                const square = rows[r][c];
                const _square = moves.rows[r][c];

                square.guess = _square.guess;
                square.notes = _square.notes;
                square.shown = square.shown || _square.correct;
                square.correct = _square.correct;
                square.mistake = _square.mistake;

                rows[r][c] = square;

                if (square.guess) {
                    validatable = true;
                }
            }
        }

        started = started - moves.elapsed;
        mistakes = moves.mistakes;
    }

    return {
        started: started,
        paused: paused,
        showResume: pause,
        rows: rows,
        mistakes: mistakes,
        completed: completed,
        stopped: stopped,
        alreadyPlayed: alreadyPlayed,
        active: {
            row: -1,
            column: -1,
        },
        highlight: -1,
        guessing: true,
        validatable: validatable,
        almost: false,
        saving: false,
        failedToSave: false,
    };
};

class Sudoku extends Component {

    constructor(props) {
        super(props);

        this.state = initialize(this, this.props.game, this.props.challenge, false);

        if (this.state.completed) {
            const elapsed = this.state.stopped - this.state.started;
            const mistakes = this.state.mistakes;

            this.startFireworks(elapsed, mistakes);
        }
    }

    componentDidMount() {
        const component = this;

        mobile.addAppStateObserver(OBSERVER_GAME, appState => {
            if (appState === appStates.ACTIVE) {
                this.resume();

            } else {
                this.pause();
            }
        });

        mobile.addAdObserver(OBSERVER_GAME, (type, status) => {
            switch (status) {
                case ads.STATUS.reward:
                case ads.RESULT.reward:
                    component.reset(true);
                    break;
                case ads.STATUS.failedToLoad:
                case ads.STATUS.failedToShow:
                case ads.RESULT.failure:
                    component.reset(false);
                    break;
                default:
                    component.cancelReset();
                    break;
            }
        });
    }

    componentWillUnmount() {
        const challenge = this.props.challenge;
        const completed = this.state.completed;

        mobile.removeAppStateObserver(OBSERVER_GAME);
        mobile.removeAdObserver(OBSERVER_GAME);

        if (!completed) {
            this.saveMoves();

        } else if (!challenge) {
            cacheService.clearCachedGame();
            cacheService.clearCachedMoves(null);

        } else {
            cacheService.clearCachedMoves(gameUtils.getChallengeId(challenge));
        }
    }

    startFireworks(elapsed, mistakes) {
        const fireworks = this.props.fireworks;
        const difficulty = this.props.game.difficulty;
        const stars = getStars(difficulty, elapsed, mistakes);

        console.log(`${stars} stars`);
        fireworks.start(stars);
    }

    stopFireworks() {
        const fireworks = this.props.fireworks;

        fireworks.stop();
    }

    clickSquare(r, c, value) {
        const _rows = this.state.rows.slice();
        const _active = objects.deepCopy(this.state.active);
        const _highlight = this.state.highlight;
        const shown = _rows[r][c].shown;

        if (!shown) {
            const alreadyActive = _active.row === r && _active.column === c;

            this.setState({
                active: {
                    row: alreadyActive ? -1 : r,
                    column: alreadyActive ? -1 : c,
                },
            });
        } else {
            const alreadyHighlighted = _highlight === value;

            this.setState({
                highlight: alreadyHighlighted ? -1 : value,
            });
        }
    }

    renderRow(r, className) {
        return <Row
            r={r}
            className={className}
            squares={this.state.rows[r]}
            active={this.state.active}
            highlight={this.state.highlight}
            almost={this.state.almost}
            completed={this.state.completed}
            paused={this.state.paused}
        />;
    }

    renderNumberButton(n) {
        const component = this;
        const guessing = this.state.guessing;
        const rows = this.state.rows.slice();
        const active = this.state.active;
        const disabled = active.row === -1 && active.column === -1;
        let selected = false;

        if (!disabled) {
            const square = rows[active.row][active.column];

            if (this.state.guessing) {
                selected = square.guess === n || square.mistake === n;

            } else {
                selected = square.notes.indexOf(n) > -1;
            }
        }

        return <Button color={guessing && !disabled ? "primary" : "secondary"}
                       size="small" variant={"outlined"}
                       className={selected ? "selected" : ""}
                       disabled={disabled}
                       onClick={() => {
                const square = rows[active.row][active.column];
                let validatable = component.state.validatable;

                square.mistake = null;

                if (component.state.guessing) {
                    square.notes = [];

                    if (square.guess === n) {
                        square.guess = null;

                        for (let r = 0; r < 9; r++) {
                            for (let c = 0; c < 9; c++) {
                                if (!validatable) {
                                    const candidate = rows[r][c];

                                    if (candidate.guess) {
                                        validatable = true;
                                    }
                                }
                            }
                        }
                    } else {
                        square.guess = n;
                        validatable = true;
                    }
                } else {
                    const index = square.notes.indexOf(n);

                    if (index > -1) {
                        square.notes.splice(index, 1);

                    } else {
                        square.notes.push(n);
                    }

                    square.guess = null;
                }

                component.setState({
                    rows: rows,
                    validatable: validatable
                }, component.saveMoves);
            }}>
            {n}
        </Button>
    }

    renderValidateButton() {
        const component = this;
        const rows = this.state.rows.slice();
        let mistakes = this.state.mistakes;

        return <Button color="primary" size="small"
            disabled={!this.state.validatable}
            onClick={() => {
                let shown = 0;

                for (let r = 0; r < 9; r++) {
                    for (let c = 0; c < 9; c++) {
                        const square = rows[r][c];
                        const guess = square.guess;
                        const value = square.value;

                        if (guess) {
                            if (guess === value) {
                                square.shown = true;
                                square.correct = true;

                            } else {
                                square.mistake = guess;
                                mistakes++;
                            }

                            square.guess = null;
                        }

                        if (square.shown) {
                            shown++;
                        }
                    }
                }

                const almost = shown > 77;
                const completed = shown === 81;
                const stopped = completed ? Date.now() : null;

                if (completed) {
                    const elapsed = stopped - this.state.started;

                    this.completedGame(elapsed);
                    this.startFireworks(elapsed, mistakes);
                }

                component.setState({
                    rows: rows,
                    validatable: false,
                    active: {
                        row: -1,
                        column: -1,
                    },
                    highlight: -1,
                    mistakes: mistakes,
                    almost: almost,
                    completed: completed,
                    stopped: stopped,
                }, component.saveMoves);
            }}>
            Validate
        </Button>
    }

    resendResult() {
        const started = this.state.started;
        const stopped = this.state.stopped;

        this.completedGame(stopped - started);
    }

    sendChallenge() {
        const started = this.state.started;
        const stopped = this.state.stopped;
        const mistakes = this.state.mistakes;
        const elapsed = stopped - started;

        this.props.onSendChallenge({
            elapsed: elapsed,
            mistakes: mistakes,
        });
    }

    completedGame(elapsed) {
        const challenge = this.props.challenge;
        const difficulty = this.props.game.difficulty;
        const best = this.props.best;
        const mistakes = this.state.mistakes;
        const success = () => this.resultSaved(elapsed, mistakes);
        const failure = this.resultFailedToSave.bind(this);

        this.setState({
            saving: true,
            failedToSave: false,
        });

        if (best && (mistakes <= best.mistakes && elapsed < best.elapsed)) {
            this.props.snackbar.setInfo("New personal best!");
        }

        if (challenge) {
            challengeService.completeChallenge(challenge.id, elapsed, mistakes, success, failure);

        } else {
            gameService.completeGame(difficulty, elapsed, mistakes, success, failure);
        }
    }

    resultSaved(elapsed, mistakes) {
        const challenge = this.props.challenge;
        const gamesPlayed = this.props.gamesPlayed;

        this.setState({
            saving: false,
        });

        if (challenge) {
            const user = this.props.user;

            const result = {
                user: user,
                metrics: {
                    elapsed: elapsed,
                    mistakes: mistakes,
                },
                created: datetime.now(),
            };

            this.props.onResultSaved(challenge.id, result);

        } else {
            cacheService.clearCachedGame();
        }

        cacheService.clearCachedMoves(gameUtils.getChallengeId(challenge));

        if (gamesPlayed >= REQUEST_REVIEW_AFTER) {
            mobile.popReview();
        }
    }

    resultFailedToSave(err) {
        this.props.snackbar.setError("Bad network connection");

        this.setState({
            saving: false,
            failedToSave: true,
        });
    }

    pause() {
        const paused = this.state.paused;

        if (!paused) {
            this.setState({
                paused: Date.now(),
                showResume: true,
            });
        }
    }

    resume() {
        const paused = this.state.paused;

        if (paused) {
            const started = this.state.started;
            const resumed = Date.now();
            const elapsed = resumed - paused;

            this.setState({
                started: started + elapsed,
                paused: null,
                showResume: false,
            });
        }
    }

    showReset() {
        const paused = this.state.paused;

        if (!paused) {
            this.setState({
                paused: Date.now(),
            }, () => {
                const reset = this.reset.bind(this);
                const failure = this.resume.bind(this);

                const success = () => {
                    if (!mobile.isMobile()) {
                        reset(false);

                    } else {
                        mobile.popRewardedAd();
                    }
                }

                // must delay in order for the CSS blur to kick in before the browser pops the confirm dialog
                mocks.delayedInvoke(() => {
                    confirm.confirmOrElse("Watch an ad to start this puzzle over?", success, failure);
                }, 10);
            });
        }
    }

    cancelReset() {
        const paused = this.state.reset;

        if (paused) {
            const started = this.state.started;
            const resumed = Date.now();
            const elapsed = resumed - paused;

            this.setState({
                started: started + elapsed,
                reset: null,
            });
        }
    }

    reset(playedAd) {
        const snackbar = this.props.snackbar;
        const game = this.props.game;
        const challenge = this.props.challenge;

        cacheService.clearCachedMoves(gameUtils.getChallengeId(challenge));

        // must clear the moves first
        const state = initialize(this, game, challenge, true);

        this.setState(state, () => {
            if (playedAd) {
                snackbar.setSuccess("Puzzle reset!");

            } else {
                snackbar.setWarning("There were no ads to show. Lucky you!");
            }
        });
    }

    saveMoves() {
        const challenge = this.props.challenge;
        const started = this.state.started;
        const mistakes = this.state.mistakes;
        const rows = this.state.rows;

        const _rows = [new Array(9), new Array(9), new Array(9),
            new Array(9), new Array(9), new Array(9),
            new Array(9), new Array(9), new Array(9)];

        for (let r = 0; r < 9; r++) {
            for (let c = 0; c < 9; c++) {
                const square = rows[r][c];
                const _square = {};

                _square.guess = square.guess;
                _square.notes = square.notes;
                _square.correct = square.correct;
                _square.mistake = square.mistake;

                _rows[r][c] = _square;
            }
        }

        const moves = {
            elapsed: Date.now() - started,
            mistakes: mistakes,
            rows: _rows,
        };

        cacheService.storeCachedMoves(gameUtils.getChallengeId(challenge), moves);
    }

    renderGrid() {
        return <div className="grid">
            {this.renderRow(0, "first")}
            {this.renderRow(1)}
            {this.renderRow(2, "third")}
            {this.renderRow(3)}
            {this.renderRow(4)}
            {this.renderRow(5, "third")}
            {this.renderRow(6)}
            {this.renderRow(7)}
            {this.renderRow(8, "third")}
        </div>
    }

    renderNumbers() {
        return <div className="numbers">
            {this.renderNumberButton(1)}
            {this.renderNumberButton(2)}
            {this.renderNumberButton(3)}
            {this.renderNumberButton(4)}
            {this.renderNumberButton(5)}
            {this.renderNumberButton(6)}
            {this.renderNumberButton(7)}
            {this.renderNumberButton(8)}
            {this.renderNumberButton(9)}
        </div>
    }

    renderOptions() {
        const component = this;
        const guessing = this.state.guessing;

        return <Table size="small" padding="none" className="options">
            <TableBody>
                <TableRow>
                    <TableCell align="left">
                        {this.renderValidateButton()}
                    </TableCell>
                    <TableCell align="right">
                        <FormControl component="fieldset">
                            <RadioGroup row={true} onChange={event => component.setState({
                                guessing: event.target.value === GUESS
                            })}>
                                <FormControlLabel
                                    value={GUESS}
                                    label="Guess"
                                    control={<Radio checked={guessing}/>}
                                />
                                <FormControlLabel
                                    value={NOTES}
                                    label="Notes"
                                    control={<Radio checked={!guessing}/>}
                                />
                            </RadioGroup>
                        </FormControl>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    }

    renderMessage() {
        const challenge = this.props.challenge;
        const msg = challenge ? "Completed!" : "You win!";
        const mistakes = this.state.mistakes;

        return <div className="message">
            <Typography variant="h5">
                {msg}
            </Typography>
            {mistakes > 0 ?
                <Typography variant="body1">
                    mistakes: <span className="mistakes">{this.state.mistakes}</span>
                </Typography> : null}
        </div>
    }

    renderHeader() {
        const user = this.props.user;
        const difficulty = this.props.game.difficulty;
        const challenge = this.props.challenge;
        const started = this.state.started;
        const stopped = this.state.stopped;
        const paused = this.state.paused || this.state.reset;

        return <Table size="small" padding="none" className="top">
            <TableBody>
                <TableRow>
                    <TableCell align="left">
                        {challenge || user.settings.showTimer ?
                            <Timer
                                started={started}
                                stopped={stopped}
                                paused={paused}
                            /> : null}
                    </TableCell>
                    <TableCell align="right">
                        {difficulty}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    }

    renderLeaderBoard() {
        const challenge = this.props.challenge;

        return challenge && challenge.results && challenge.results.length > 0 ?
            <>
                <Divider/>
                <LeaderBoard
                    results={challenge.results}
                    scoreLabel="Time"
                    toScore={toScore}
                    subLabel="Mistakes"
                    toSub={toSub}
                    sorter={sortByMistakesAndElapsed}
                />
            </> : null;
    }

    renderActions() {
        const challenge = this.props.challenge;
        const completed = this.state.completed;
        const saving = this.state.saving;
        const failedToSave = this.state.failedToSave;

        const renderHomeButton = () => {
            return <Button
                color="secondary" variant="text"
                onClick={() => {
                   const callback = failedToSave || (challenge && challenge.mine)
                       ? this.props.onBack
                       : this.props.onDone;

                   if (failedToSave) {
                       confirm.confirm(games.RESULTS_NOT_SAVED, callback);

                   } else {
                       callback();
                   }
               }}
            >
                Home
            </Button>
        };

        const renderSaveButton = () => {
            return <Button
                color="primary" size="small" className="save"
                onClick={this.resendResult.bind(this)}
            >
                <SaveIcon/>Resend results
            </Button>
        };

        const renderRematchButton = () => {
            return <Button
                color="primary" size="small" className="play-again"
                onClick={this.props.onRematch}
            >
                <RematchIcon/>Rematch
            </Button>
        };

        const renderSendChallengeButton = () => {
            return <Button
                color="primary" size="small" className="play-again"
                onClick={this.sendChallenge.bind(this)}
            >
                <SendChallengeIcon/>Send challenge
            </Button>
        };

        const renderPlayAgainButton = () => {
            return <Button
                color="primary" size="small" className="play-again"
                onClick={this.props.onPlayAgain}
            >
                <RematchIcon/>Play again
            </Button>
        };

        return <>
            <Divider/>
            <div className="actions">
            {completed ?
                <>
                    {saving ?
                        <CircularProgress size="36px" color="primary"/> : challenge ?
                        <>
                            {renderHomeButton()}
                            {failedToSave ?
                                renderSaveButton() : challenge.allowRematch ?
                                renderRematchButton() : null}
                        </> :
                        <>
                            {renderSendChallengeButton()}
                            {renderPlayAgainButton()}
                            <div className="lower">
                                {renderHomeButton()}
                            </div>
                        </>}
                </> :
                <>
                    <Button color="secondary" size="small" variant="outlined" className="pause"
                            onClick={() => {
                                this.saveMoves();
                                this.props.onQuit();
                            }}>
                        <PauseIcon/>Pause
                    </Button>
                    <Button color="secondary" size="small" className="reset"
                            onClick={this.showReset.bind(this)}>
                        <AdIcon/>Restart
                    </Button>
                </>}
            </div>
        </>
    }

    render() {
        const completed = this.state.completed;
        const paused = this.state.paused;
        const showResume = this.state.showResume;

        return completed ?
            <div className="sudoku">
                {this.renderHeader()}
                {this.renderMessage()}
                {this.renderLeaderBoard()}
                {this.renderActions()}
            </div> :
            <div className={"sudoku" + (paused ? " paused" : "")}>
                {this.renderHeader()}
                {this.renderGrid()}
                {this.renderNumbers()}
                {this.renderOptions()}
                {this.renderLeaderBoard()}
                {this.renderActions()}
                <Backdrop open={showResume}
                          onClick={this.resume.bind(this)}>
                    <ResumeIcon className="play"/>
                </Backdrop>
            </div>
    }
}

export default withRouter(Sudoku);
