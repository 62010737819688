import {storage} from "@atttomyx/react-utils";
import {arrays, objects} from "@atttomyx/shared-utils";
import {CHALLENGE_ID_NONE, KEY_GAME, KEY_LEADER_BOARDS, KEY_MOVES, KEY_RESULT, KEY_USERS} from "../constants";

const local = storage.getLocal();
const session = storage.getSession();

export const clearCachedUsers = () => {
    local.clear(KEY_USERS);
};

export const getCachedUsers = () => {
    return local.getObj(KEY_USERS) || [];
};

const storeCachedUsers = (users) => {
    local.setObj(KEY_USERS, users);
};

export const syncCachedUser = (user) => {
    let users = getCachedUsers();

    users = arrays.syncSavedEntity(users, user);

    storeCachedUsers(users);
};

export const syncCachedUsers_leaderBoard = (leaderBoard) => {
    let users = getCachedUsers();

    if (leaderBoard.gamesPlayed) {
        leaderBoard.gamesPlayed.forEach(gamesPlayed => {
            users = arrays.syncSavedEntity(users, gamesPlayed.user);
        });
    }

    if (leaderBoard.results) {
        leaderBoard.results.forEach(results => {
            users = arrays.syncSavedEntity(users, results.user);
        });
    }

    storeCachedUsers(users);
};

export const syncCachedUsers_game = (game) => {
    let users = getCachedUsers();

    if (game.results) {
        game.results.forEach(results => {
            users = arrays.syncSavedEntity(users, results.user);
        });
    }

    if (game.mine) {
        users = arrays.syncSavedEntity(users, game.mine.user);
    }

    storeCachedUsers(users);
};

export const syncCachedUsers_challenge = (challenge) => {
    let users = getCachedUsers();

    if (challenge.opponent) {
        users = arrays.syncSavedEntity(users, challenge.opponent);
    }

    if (challenge.targets) {
        challenge.targets.forEach(target => {
            users = arrays.syncSavedEntity(users, target);
        });
    }

    if (challenge.best) {
        users = arrays.syncSavedEntity(users, challenge.best.user);
    }

    if (challenge.mine) {
        users = arrays.syncSavedEntity(users, challenge.mine.user);
    }

    if (challenge.theirs) {
        users = arrays.syncSavedEntity(users, challenge.theirs.user);
    }

    storeCachedUsers(users);
};

export const getCachedLeaderBoards = () => {
    return local.getObj(KEY_LEADER_BOARDS) || {};
};

export const storeCachedLeaderBoards = (leaderBoards) => {
    local.setObj(KEY_LEADER_BOARDS, leaderBoards);
};

export const clearCachedLeaderBoards = () => {
    local.clear(KEY_LEADER_BOARDS);
};

export const getCachedGame = () => {
    return local.getObj(KEY_GAME);
};

export const storeCachedGame = (game) => {
    local.setObj(KEY_GAME, game);
};

export const clearCachedGame = () => {
    local.clear(KEY_GAME);
};

export const isPaused = (challengeId) => {
    const moves = getCachedMoves(challengeId);

    return objects.notNullOrUndefined(moves.rows);
}

const getMovesCache = () => {
    const backwardsCompatible = local.getObj(KEY_MOVES) || {};

    return Array.isArray(backwardsCompatible) ? {} : backwardsCompatible;
};

export const getCachedMoves = (challengeId) => {
    const key = getSafeChallengeId(challengeId);
    const cache = getMovesCache();

    return cache[key] || [];
};

export const storeCachedMoves = (challengeId, moves) => {
    const key = getSafeChallengeId(challengeId);
    const cache = getMovesCache();

    cache[key] = moves;

    local.setObj(KEY_MOVES, cache);
};

export const clearCachedMoves = (challengeId) => {
    const key = getSafeChallengeId(challengeId);
    const cache = getMovesCache();

    delete cache[key];

    local.setObj(KEY_MOVES, cache);
};

const getSafeChallengeId = (challengeId) => challengeId || CHALLENGE_ID_NONE;

export const getCachedResult = () => {
    return session.getObj(KEY_RESULT);
};

export const storeCachedResult = (result) => {
    session.setObj(KEY_RESULT, result);
};

export const clearCachedResult = () => {
    session.clear(KEY_RESULT);
};
