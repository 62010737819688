import {objects, time} from "@atttomyx/shared-utils";
import {constants as games} from "@atttomyx/react-games";
import {DEFAULT_BEST} from "../constants";

export const toScore = (metrics) => time.formatTime(metrics.elapsed);
export const toSub = (metrics) => metrics.mistakes;

export const getBest = (stats, difficulty) => {
    let best;

    if (stats) {
        switch (difficulty) {
            case games.EASY:
                if (stats.easy) {
                    best = stats.easy.best;
                }
                break;
            case games.MEDIUM:
                if (stats.medium) {
                    best = stats.medium.best;
                }
                break;
            case games.HARD:
                if (stats.hard) {
                    best = stats.hard.best;
                }
                break;
            default:
                console.log("unsupported: " + difficulty);
        }
    }

    return objects.defaultIfNullOrUndefined(best, DEFAULT_BEST);
};

export const getStars = (difficulty, elapsed, mistakes) => {
    const units = time.getUnits(elapsed);
    let stars = 0;

    if (units.hours > 0) {
        stars = 1;

    } else {
        switch (difficulty) {
            case games.EASY:
                if (units.minutes < 1) {
                    if (mistakes > 0) {
                        stars = 2;

                    } else {
                        stars = 3;
                    }
                } else if (units.minutes < 2) {
                    if (mistakes > 0) {
                        stars = 1;

                    } else {
                        stars = 2;
                    }
                } else {
                    stars = 1;
                }
                break;
            case games.MEDIUM:
                if (units.minutes < 2) {
                    if (mistakes > 0) {
                        stars = 2;

                    } else {
                        stars = 3;
                    }
                } else if (units.minutes < 4) {
                    if (mistakes > 0) {
                        stars = 1;

                    } else {
                        stars = 2;
                    }
                } else {
                    stars = 1;
                }
                break;
            case games.HARD:
                if (units.minutes < 5) {
                    if (mistakes > 0) {
                        stars = 2;

                    } else {
                        stars = 3;
                    }
                } else if (units.minutes < 10) {
                    if (mistakes > 0) {
                        stars = 1;

                    } else {
                        stars = 2;
                    }
                } else {
                    stars = 1;
                }
                break;
            default:
                console.log("unsupported: " + difficulty);
        }
    }

    return stars;
};
