import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import {time} from "@atttomyx/shared-utils";
import {time as timeConstants} from "@atttomyx/shared-constants";
import './timer.css';

const Timer = (props) => {
    const { started, stopped, paused } = props;
    const [elapsed, setElapsed] = useState(0);
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (started && stopped) {
            setElapsed(stopped - started);
            setActive(false);
        } else {
            setElapsed(Date.now() - started)
            setActive(true);
        }
    }, [started, stopped]);

    useEffect(() => {
        let interval = null;
        if (paused || !active) {
            clearInterval(interval);
        } else {
            interval = setInterval(() => {
                setElapsed(elapsed => elapsed + timeConstants.MILLISECONDS_IN_SECOND);
            }, timeConstants.MILLISECONDS_IN_SECOND);
        }
        return () => clearInterval(interval);
    }, [paused, elapsed, active]);

    return <Typography component="div" className="timer">
        {time.formatTime(elapsed)}
    </Typography>
};

export default Timer;
