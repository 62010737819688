import React from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {forms, objects, strings} from "@atttomyx/shared-utils";
import {themes} from "@atttomyx/shared-constants";
import {themes as themeUtils} from "@atttomyx/react-utils";
import {constants as games} from "@atttomyx/react-games";

const UserSettingsForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        if (field === "theme") {
            modified.primaryColor = themeUtils.defaultPrimary(value);
            modified.secondaryColor = themeUtils.defaultSecondary(value);
            modified.backgroundColor = themeUtils.defaultBackground(value);
        }

        modified.valid = strings.isNotBlank(modified.theme)
            && strings.isNotBlank(modified.primaryColor)
            && strings.isNotBlank(modified.secondaryColor)
            && strings.isNotBlank(modified.backgroundColor)
            && strings.isNotBlank(modified.difficulty)

        onSync(modified);
    }

    return <div className="user-settings-form">
        <div className="field">
            <FormControl component="fieldset">
                <RadioGroup row={true} onChange={(event) => onChange("theme", event.target.value)}>
                    <FormControlLabel
                        value={themes.LIGHT}
                        label="Light"
                        control={<Radio checked={data.theme === themes.LIGHT} color="primary"/>}
                    />
                    <FormControlLabel
                        value={themes.DARK}
                        label="Dark"
                        control={<Radio checked={data.theme === themes.DARK} color="primary"/>}
                    />
                </RadioGroup>
            </FormControl>
        </div>
        <div className="field">
            <TextField label="Primary color" required={true}
                       type="color"
                       value={forms.sanitizeValue(data.primaryColor)}
                       onChange={(event) => onChange("primaryColor", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Secondary color" required={true}
                       type="color"
                       value={forms.sanitizeValue(data.secondaryColor)}
                       onChange={(event) => onChange("secondaryColor", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Background color" required={true}
                       type="color"
                       value={forms.sanitizeValue(data.backgroundColor)}
                       onChange={(event) => onChange("backgroundColor", event.target.value)}
            />
        </div>
        <Typography variant="h5" paragraph={true} className="section">
            Game
        </Typography>
        <div className="field">
            <TextField label="Default difficulty" select={true} required={true}
                       value={forms.sanitizeValue(data.difficulty)}
                       onChange={(event) => onChange("difficulty", event.target.value)}
            >
                <MenuItem key={games.EASY} value={games.EASY}>Easy</MenuItem>
                <MenuItem key={games.MEDIUM} value={games.MEDIUM}>Medium</MenuItem>
                <MenuItem key={games.HARD} value={games.HARD}>Hard</MenuItem>
            </TextField>
        </div>
        <div className="option">
            <FormControlLabel label="Show timer in single-player"
                              control={<Checkbox checked={forms.sanitizeOption(data.showTimer)}
                                                 onChange={(event) => onChange("showTimer", event.target.checked)}
                              />}
            />
        </div>
    </div>
}

export default UserSettingsForm;
